@import "../../../assets/sass/var";

//* {
//  box-sizing: border-box;
//}
.online-date {
  box-sizing: border-box;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

//h2 {
//  margin: 0;
//}

p {
  margin: 0;
}


.online-date {
  display: inline;
  padding: 20px 0;
  background: #F6F6F8;
  margin-bottom: -30px;

  form {
    text-align: initial !important;
  }

  input {
    opacity: 0 !important;
  }

  h2 {
    margin: 0;
    color: black !important;
  }

  .checkbox {
    margin-top: 16px;
    margin-bottom: 0;
    margin-left: 16px;

    &__label, &__labelPhoto {
      margin-left: 50px;
      font-size: 16px;
      font-family: "PoppinsRegular", "PoppinsBold";
      color: $paragraph-text-background;
    }
  }

  .radioTerminow {
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 16px;

    &__label, &__labelPhoto {
      margin-left: 50px;
      font-size: 16px;
      font-family: "PoppinsRegular", "PoppinsBold";
      color: $paragraph-text-background;
    }
  }

  .button {
    margin-right: 12px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: normal;
    color: $paragraph-text-background;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .button:hover {
    color: $btn-text-color;
  }

  .header {
    display: flex;
    justify-content: center;
    margin-left: -700px;

    &__info {
      margin: 40px 0 20px 120px;

      &-name1 {
        font-size: 14px;
        font-weight: bolder;
        color: $paragraph-text-background;
        line-height: 32px;
        letter-spacing: 3px;
      }

      &-name2 {
        font-size: 64px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 69px;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto;
    min-width: 400px;
    //max-width: 850px;
    max-width: 1200px;
    //border: 1px solid #737484;
    padding: 5px;


    .choice {
      height: 100%;
      min-height: 100px;
      background-color: $btn-text-color;
      border-radius: 24px;
      border: 1px solid #E7E6E6;
      min-width: 280px;
      flex: 1 2 280px;
      margin: 10px 10px 20px 10px;
      display: inline-block;

      @media (min-width: 800px) and (max-width: 1200px) {

      }

      &__incurance {
        margin: 24px 30px 0px 24px;

        &-name {
          font-size: 20px;
          font-weight: bolder;
          letter-spacing: 0.3px;
          line-height: 30px;
          margin-bottom: 5px;
          font-family: "PoppinsRegular", "PoppinsBold";
          color: black !important;
        }

        .radio-elem {
          margin-left: -16px;

          &:nth-last-child(-n+1) {
            margin-bottom: 10px;
          }
        }

        &-label {
          display: block;
          z-index: 100;
        }

        &__btn {
          color: white;
        }
      }

      &__reason {
        margin: 0px 30px 0px 24px;

        &-name {
          font-size: 20px;
          font-weight: bolder;
          letter-spacing: 0.3px;
          line-height: 30px;
          margin-bottom: 5px;
          font-family: "PoppinsRegular", "PoppinsBold";
          color: black !important;
        }

        .checkbox-reason {
          margin-left: -17px;
        }

        .radio-elem {

          &:nth-last-child(-n+1) {
            margin-bottom: 10px;
          }
        }
      }

      .checkbox-reason {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
      }

      &__doctor {
        margin: 0px 30px 24px 24px;

        &-name {
          font-size: 20px;
          font-weight: bolder;
          letter-spacing: 0.3px;
          line-height: 30px;
          margin-bottom: 5px;
          font-family: "PoppinsRegular", "PoppinsBold";
          color: black !important;
        }

        //.checkbox-doctor {
        //  margin-left: -17px;
        //}

        .radio-elem {
          margin-left: -16px;

          &:nth-last-child(-n+1) {
            margin-bottom: 10px;
          }
        }
      }

      //.checkbox-doctor {
      //  display: flex;
      //  flex-wrap: wrap;
      //  justify-content: left;
      //}

      &__btn {
        margin-top: 50px;
        margin-bottom: 24px;
        margin-left: 24px;
      }

      &__btn2 {
        background-color: transparent;
        display: none;
      }
    }

    .calendar-null {
      height: 588px;
      clear: right;
      float: left;
      background: #FFFFFF;
      border: 1px solid #E7E6E6;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 500px;
      flex: 1 2 500px;
      margin: 10px 10px 20px 10px;

      &__info1 {
        overflow: hidden;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-img {
          margin-bottom: 27px;
          display: block;
          margin-left: auto;
          margin-right: auto
        }

        &-text {
          color: $paragraph-text-background;
          width: 261px;
          font-size: 16px;
          letter-spacing: 0.25px;
          line-height: 24px;
          text-align: center;
        }
      }

      &__info {
        overflow: visible;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-img {
          margin-bottom: 27px;
          display: block;
          margin-left: auto;
          margin-right: auto
        }

        &-text {
          color: $paragraph-text-background;
          width: 261px;
          font-size: 16px;
          letter-spacing: 0.25px;
          line-height: 24px;
          text-align: center;
        }
      }
    }

    .calendar {
      overflow: inherit;
      clear: right;
      float: left;
      padding: 0px 24px;
      padding-bottom: 20px;
      background: #FFFFFF;
      border: 1px solid #E7E6E6;
      box-sizing: border-box;
      border-radius: 24px;
      height: 100%;
      display: inline-block;
      min-width: 500px;
      max-width: 100%;
      flex: 1 2 500px;
      margin: 10px 10px 20px 10px;

      @media (min-width: 800px) and (max-width: 1200px) {
        display: block;
        width: 100%;
      }

      &__info {
        width: 100%;
        padding: 20px 0 20px 0;

        &-control-block {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .prev {
            width: 18%;
            text-align: left;
          }

          .prev:hover {
            cursor: pointer;
          }

          .prev-null {
            width: 18%;
            opacity: 0;
          }

          .week {
            width: 60%;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.3px;
            color: #2A2A30;
          }

          .next {
            width: 18%;
            text-align: right;
          }

          .next:hover {
            cursor: pointer;
          }

          .next-null {
            width: 18%;
            opacity: 0;
          }
        }

        .border-up {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .column-calendar {
          display: flex;
          min-height: 80%;

          .list-time {
            width: 100%;
            border-right: 10px solid #fff;
            padding: 0px;
            display: flex;
            flex-direction: column;
            list-style-type: none;


            border-right: 0px solid #fff;
            min-width: 60px;
            //max-width: 150px;
            max-width: 100%;

            .time-item {
              text-align: center;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.25px;
              color: #737484;
              list-style-type: none;
              min-width: 50px;
              max-width: 100%;
              margin: 10px;
              display: inline-block;
              padding-inline-start: 0;

              .title-date {
                display: block;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: #2A2A30;
              }

              .title-time {
                margin: 10px 5px;
                padding: 6px 0;
                background: #EFF0F6;
                border-radius: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.25px;
                color: #737484;
                cursor: pointer;
                min-width: 60px;
                max-width: 100%;
                width: 100%;
                display: inline-block;
              }

              .title-time-chosen {
                margin: 10px 5px;
                padding: 6px 0;
                background: #49CD6E;
                border-radius: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.25px;
                color: #FFFFFF;
                cursor: pointer;
                min-width: 60px;
                max-width: 100%;
                width: 100%;
                display: inline-block;
              }
            }

            &:nth-last-child(-n+1) {
              border-right: 0px solid #fff;
            }
          }
        }

        &__calendar-general {
          width: 100% !important;
          display: inline-block;

          .react-calendar {
            width: 100% !important;
            display: block;

            &__navigation {
              &__label:hover {
                background: none;
              }
              &__arrow:hover {
                background: none;
              }
            }
          }

          @media (min-width: 800px) and (max-width: 1200px) {
            .react-calendar {
              width: 100% !important;
              display: block;

              &__navigation {
                &__label:hover {
                  background: none;
                }
                &__arrow:hover {
                  background: none;
                }
              }
            }
          }

          &__time-block {
            height: 65px;
            width: 100%;
            padding: 0 15px 0 15px;

            @media (min-width: 800px) and (max-width: 1200px) {
              padding: 0 10px 0 10px;
            }

            &_label-select {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }
      }

      .links-block {
        display: flex;
        justify-content: space-around;
        margin-top: 16px;

        .btn-link-disabled {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 48px;
          text-align: center;
          letter-spacing: 1px;
          color: #737484;
        }

        .btn-link {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 48px;
          text-align: center;
          letter-spacing: 1px;
          color: #2A2A30;
        }

        .btn-link:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .button {
        &_color-green {
          background-color: $day-color;
          color: $btn-text-color;
        }
      }

      .client-btn {
        width: 100%;
      }
    }

    .general-error {
      letter-spacing: $help-text-spacing;
      font-size: $general-error-text-size;
      color: $input-color-help-text;
      text-align: center;
    }
  }
}


@media screen and (max-width: 799px) {
  .online-date {
    display: inline;

    .content {
      grid-gap: 0;
      display: flex;
      flex-wrap: wrap;
      min-width: 320px;
      max-width: 850px;
      //max-width: 335px;

      .button {
        //padding: 16px 133px;
        padding: 16px 60px;
      }

      .button:hover {
        background-color: black;
      }

      .choice {
        margin: 20px 0 20px 0;
        min-width: 100%;
        max-width: 100%;
        display: inline-block;

        &__incurance {
          &-name {
            font-size: 18px;
            line-height: 27px;
          }
        }

        &__btn {
          display: none;
        }

        &__btn2 {
          display: flex;
          width: 327px;
          margin-left: 3px;
          margin-top: -50px;
          margin-bottom: 20px;
        }
      }

      .css-1afjkum-control {
        margin-top: 7px !important;
      }


      .calendar {
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        padding: 0px 5px 20px 5px;
        margin: 10px 5px 20px 5px;

        .links-block {
          margin-right: 7px;

          @media (min-width: 0) and (max-width: 400px) {
            //width: 110%;
            //margin-left: -25px;
            width: 100%;
          }

          .btn-link {

            @media (min-width: 0) and (max-width: 400px) {
              //width: 180px;
              font-size: 10px;
            }
          }
        }

        &__info {
          width: 100%;
          padding: 20px 0px 20px 0px;
          min-height: 374px !important;

          &__calendar-general {
            &__time-block {
              display: block;
              margin-bottom: 15px;
            }

            .react-calendar {
              width: 100% !important;
              display: block;

              abbr {
                font-size: 15px;
              }

              &__month-view__weekdays {
                &__weekday {
                  margin: 0 !important;
                }
              }

            }

          }


          .column-calendar {
            width: 100%;
            min-width: 300px;
            max-width: 100%;
            padding: 0 5px;

            .list-time {
              min-width: 40px;
              max-width: 100%;

              .time-item {
                padding-inline-start: 0;
                min-width: 45px;
                max-width: 100%;
                margin: 10px 5px 10px 5px;


                .title-time-chosen {
                  font-size: 10px;
                  margin: 5px 5px;
                  padding: 3px 0;
                  min-width: 30px;
                  max-width: 100%;
                  width: 80%;
                }

                .title-date {
                }


                .title-time {
                  font-size: 10px;
                  margin: 5px 5px;
                  padding: 3px 0px;
                  min-width: 30px;
                  max-width: 100%;
                  width: 80%;
                }
              }
            }
          }

        }
      }

      .calendar-null {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}


.button {
  border-radius: 10px;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  color: $btn-text-color;
  letter-spacing: 0.25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding: 16px 60px;

  &_size {
    &-small {
      padding: 8px 12px;
      font-size: 14px;
      line-height: 21px;
    }

    &-big {
      padding: 16px 154px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  &_position {
    &-left {
      float: left;
    }

    &-center {
      display: table;
      margin-left: auto;
      margin-right: auto;
    }

    &-right {
      float: right;
    }
  }

  &_color {
    &-gray {
      background-color: $btn-color-gray;
      color: $paragraph-text-background;
      cursor: pointer;
    }

    &-gray:hover {
      background-color: $day-color;
      color: white;
    }

    &-gray:disabled {
      opacity: 0.9;
    }

    &-gray2 {
      background-color: #EFF0F6;
      color: #737484;
      font-weight: bold;
      cursor: pointer;
    }

    &-gray2:hover {
      background-color: #2A2A30;
      color: white;
      font-weight: bold;
    }

    &-green {
      border: 2px solid $day-color;
      color: $day-color;
      background: transparent;
      cursor: pointer;
    }

    &-green:hover {
      background-color: $day-color;
      border: 2px solid $day-color;
      color: white;
    }

    &-black {
      background-color: $btn-background;
      color: white;
      cursor: pointer;
      border: 2px solid black;
    }

    &-disabled {
      background-color: #EFF0F6;
      color: $paragraph-text-background;
    }

    &-black:hover {
      background-color: $day-color;
      color: white;
      border: 2px solid $day-color;
    }

    &-transparent {
      background-color: transparent;
      border: 2px solid $btn-border;
      color: $btn-background;
      text-decoration: none;
      cursor: pointer;
    }

    &-transparent:hover {
      background-color: $btn-hover-blue;
      color: $btn-text-color;
      border: 2px solid $btn-hover-blue;
    }

    &-delete {
      color: $btn-background;
      background-color: transparent;
      border: 2px solid #fd510dc9;
      color: #fd510dc9;
      cursor: pointer;
    }

    &-delete:hover {
      background-color: #fd510d;
      color: white;
    }

    &-blue {
      background-color: $btn-hover-blue;
      border: 2px solid $btn-hover-blue;
      color: white;
      text-decoration: none;
      cursor: pointer;
    }

    &-blue2 {
      background-color: transparent;
      border: 2px solid $btn-hover-blue;
      color: $btn-hover-blue;
      cursor: pointer;

    }

    &-blue2:hover {
      background-color: $btn-hover-blue;
      border: 2px solid $btn-hover-blue;
      color: white;
      cursor: pointer;
    }
  }
}
